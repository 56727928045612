<template>
  <div class="page__container">
    <div class="page__content notFound__content">
      <aj-text
        tag="h2"
        headline-medium
        class="notFound__headline"
      >
        Sorry, we can't find the page you're seeking.
      </aj-text>
      <aj-text
        tag="h2"
        body-large
        class="notFound__subtitle"
      >
        It might have been removed or relocated.
      </aj-text>
      <aj-button
        tag="router-link"
        :to="{ path: routeMixinHomePageRoutePath }"
        :title="translate('seo.title.home')"
        class="notFound__link"
        primary
        large
      >
        Go to homepage
      </aj-button>
    </div>
  </div>
</template>

<style lang="scss">
  .notFound {
    &__content {
      padding: 50px 0;
    }
    &__headline {
      margin-bottom: 10px;
      @media (min-width: $desktop) {
        margin-bottom: 0;
      }
    }
    &__subtitle {
      margin-bottom: 20px;
    }
    &__link {
      display: inline-block;
    }
  }
</style>

<script>
import AjButton from '@appjobs/ui/src/components/AjButton/AjButton';
import AjText from '@appjobs/ui/src/components/AjText/AjText';
import { seoTitle } from '@/js/helpers/seo';
import routesMixin from '@/js/mixins/routes';

export default {
  name: 'NotFound',

  components: {
    AjButton,
    AjText,
  },

  mixins: [routesMixin],

  created () {
    seoTitle('404');
  },
};
</script>
